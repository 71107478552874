.Contacto {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ContactoGrid {
  padding: 40px;
}

@media only screen and (max-width: 767px) {
  .ContactoGrid {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 32px;
  }

  .ProyectosCardWrapper {
    padding: 0 10px 20px;
  }
}
