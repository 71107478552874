.Proyectos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProyectosGrid {
  padding: 40px;
}

.ProyectosCard {
  width: 100%;
}

.ProyectosCardActions {
  display: flex;
  justify-content: flex-end;
}

.ProyectosCardWrapper {
  padding: 0 18px 32px;
}

@media only screen and (max-width: 767px) {
  .ProyectosGrid {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ProyectosCardWrapper {
    padding: 0 10px 20px;
  }
}
