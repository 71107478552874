.ContactoFormCard {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .ContactoFormCardWrapper {
    padding: 0 10px 20px;
  }
}

