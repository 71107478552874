body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MainBar_MainBar__2wbEX {
  display: flex;
  flex-grow: 1;
  margin-bottom: 64px;
}

.MainBar_MainBarName__Dn4k8 {
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .MainBar_MainBar__2wbEX {
    margin-bottom: 56px;
  }
}

.MainSec_MainSec__2A42Q {
  position: relative;
}

.MainSec_MainSecImgBg__3GAkX {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 64px);
  max-height: 1080px;
  background-size: cover !important;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  box-shadow: inset 0 0 0 2000px rgba(0,0,0,0.7);
}

.MainSec_MainSecTextWrapper__1N0g_ {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.MainSec_MainSecTextHeader__1qMHw {
  border-bottom: 1px solid #fff !important;
}

.MainSec_MainSecTextHeader__1qMHw h1 {
  padding-left: 75px;
  padding-right: 75px;
  font-size: 156px;
  margin-bottom: 0;
}

.MainSec_MainSecTextHeader__1qMHw h5 {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: right;
}

.MainSec_MainSecTextContent__17lQL {
  width: 75%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 32px;
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .MainSec_MainSecImgBg__3GAkX {
    height: calc(100vh - 56px);
  }

  .MainSec_MainSecTextHeader__1qMHw h1 {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 88px;
  }

  .MainSec_MainSecTextHeader__1qMHw h5 {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: right;
  }

  .MainSec_MainSecTextContent__17lQL {
    width: 75%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 26px;
  }
}

.SectionTitle_SectionTitle__1TSHi {
  text-align: center;
  font-size: 46px;
}

.SectionTitle_SectionTitle__1TSHi h1 {
  margin-bottom: 0;
}

.SectionTitle_SectionTitle__1TSHi hr {
  margin-top: 15px;
  margin-bottom: 0;
  width: 12%;
  border: 2px solid #666;
}
.Servicios_Servicios__1rotI {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Servicios_ServiciosCard__2rbZ_ {
  width: 100%;
  min-height: 430px;
}

.Servicios_ServiciosGrid__1lZa1 {
  padding: 40px;
}

.Servicios_ServiciosCardWrapper__3G89g {
  padding: 0 18px 32px;
}

@media only screen and (max-width: 767px) {
  .Servicios_ServiciosGrid__1lZa1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .Servicios_ServiciosCardWrapper__3G89g {
    padding: 0 10px 20px;
  }
}
.SectionText_SectionText__2CYx9 {
  width: 75%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 28px;
}

@media only screen and (max-width: 767px) {
  .SectionText_SectionText__2CYx9 {
    width: 75%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 22px;
  }
}
.Proyectos_Proyectos__3Eol6 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Proyectos_ProyectosGrid__3yd7w {
  padding: 40px;
}

.Proyectos_ProyectosCard__A09J2 {
  width: 100%;
}

.Proyectos_ProyectosCardActions__61fmm {
  display: flex;
  justify-content: flex-end;
}

.Proyectos_ProyectosCardWrapper__2HGin {
  padding: 0 18px 32px;
}

@media only screen and (max-width: 767px) {
  .Proyectos_ProyectosGrid__3yd7w {
    padding-left: 10px;
    padding-right: 10px;
  }

  .Proyectos_ProyectosCardWrapper__2HGin {
    padding: 0 10px 20px;
  }
}

.Contacto_Contacto__1HHdV {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Contacto_ContactoGrid__1BQDB {
  padding: 40px;
}

@media only screen and (max-width: 767px) {
  .Contacto_ContactoGrid__1BQDB {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 32px;
  }

  .Contacto_ProyectosCardWrapper__3FhHR {
    padding: 0 10px 20px;
  }
}

.ContactoForm_ContactoFormCard__Ampxh {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .ContactoForm_ContactoFormCardWrapper__2i6Qa {
    padding: 0 10px 20px;
  }
}


