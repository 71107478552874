.MainSec {
  position: relative;
}

.MainSecImgBg {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 64px);
  max-height: 1080px;
  background-size: cover !important;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  box-shadow: inset 0 0 0 2000px rgba(0,0,0,0.7);
}

.MainSecTextWrapper {
  width: max-content;
}

.MainSecTextHeader {
  border-bottom: 1px solid #fff !important;
}

.MainSecTextHeader h1 {
  padding-left: 75px;
  padding-right: 75px;
  font-size: 156px;
  margin-bottom: 0;
}

.MainSecTextHeader h5 {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: right;
}

.MainSecTextContent {
  width: 75%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 32px;
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .MainSecImgBg {
    height: calc(100vh - 56px);
  }

  .MainSecTextHeader h1 {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 88px;
  }

  .MainSecTextHeader h5 {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: right;
  }

  .MainSecTextContent {
    width: 75%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 26px;
  }
}
