.SectionText {
  width: 75%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 28px;
}

@media only screen and (max-width: 767px) {
  .SectionText {
    width: 75%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 22px;
  }
}