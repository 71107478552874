.MainBar {
  display: flex;
  flex-grow: 1;
  margin-bottom: 64px;
}

.MainBarName {
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .MainBar {
    margin-bottom: 56px;
  }
}
