.SectionTitle {
  text-align: center;
  font-size: 46px;
}

.SectionTitle h1 {
  margin-bottom: 0;
}

.SectionTitle hr {
  margin-top: 15px;
  margin-bottom: 0;
  width: 12%;
  border: 2px solid #666;
}